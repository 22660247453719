function getConfigValue(processEnvName, fallbackValue) {
  const value = process.env[processEnvName];
  return value !== undefined && value.trim() !== '' ? value : fallbackValue;
}

const loadConfig = {
  getConfig() {
    return {
      apiBaseUrl: getConfigValue('REACT_APP_API_URL', 'https://srv002.clintouch-swl.org'),
      apiProxyUrl: getConfigValue('REACT_APP_PROXY_URL', 'https://srv001.clintouch-swl.org'),
      remoteProxyUrl: 'https://srv001.clintouch-swl.org',
      proxyPassword: 'nFSgb35tjk4h5!b34F9jk4·Djh!1!2rfd'
    };
  }
};

export const config = loadConfig.getConfig();
